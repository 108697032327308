import React from "react";
import "./App.css";
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import Header from "./Component/Header";
import Policy from "./Component/Policy";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home}/>

        <Route exact path="/policy" component={Policy}/>
      </Switch>
      <Header />
      
      <a class="scrolltop scrolltopActive" href="#"></a>
      <Footer />
    </div>
    </Router>
  );
}

export default App;
