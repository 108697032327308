import React, { useState } from "react";
import logo from "../assets/static/mindout-concept-black.jpg";

function Header() {
  const [show, setShow] = useState(false);

  return (
    <div class="top-header">
      <nav id="navbar" class="navbar navbar-expand-md navbar-light fixed-top ">
        <div class="container ">
          <a class="navbar " href="/">
            <img
              // src="../assets/static/mindout-infosystem.png"
              src={logo}
              width="120px"
              height="50px"
              alt="Logo"
            />
          </a>

          <button
            class="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              class="navbar-toggler-icon"
              onClick={() => {
                setShow(!show);
              }}
            ></span>
          </button>
          <div
            class={
              show
                ? "navbar-collapse collapse"
                : "navbar-collapse collapse show"
            }
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a
                  class="nav-link "
                  href="/"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/#services"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Services
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/#works"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Works
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/#about"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  About
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/#contact"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Contact
                </a>
              </li>
              {/* <li class="nav-item">
                <a
                  class="nav-link"
                  href="/policy"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Policy
                </a>
              </li> */}
            </ul>
          </div>
          <div class="header-search">
            <button class="search-toggle">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
