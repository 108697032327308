import React from "react";

function About() {
  return (
    <div>
      <div id="about" class="about-us">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
              <div class="section-title text-center">
                <h2>About Us</h2>
                <p>
                  Our company is focused on custom software and application
                  development outsourcing across multiple technologies including
                  ( or, react, python, mention our technology services) With
                  more than 50 successful projects marked complete globally and
                  over 100 highly experienced developers teams, we are rated as
                  one of the top software and applications development company
                  in India.
                </p>
                <span class="section-title-bg">About</span>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-text">
                <h3>Why we are unique</h3>
                <p>
                  We develop custom software and applications that help your
                  business do more. Our expert development teams build solutions
                  that make business processes more efficient, improve customer
                  experience and generate more profit for our clients.
                </p>
                <p>
                  We are keeping our technology upgrade all time to make the
                  development process smooth and easy going for clients and
                  search engines to give a fantastic experience to website
                  visitors.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <img
                src="https://gloxy-react.envytheme.com/static/1154f2cfaf53487b50efbec3a1a817d6/14b42/about-img.jpg"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
