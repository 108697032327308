import React from "react";

function Achievement() {
  return (
    <div>
      <section class="fun-facts ptb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
              <div class="section-title">
                <h2>Some Fun Facts</h2>
                <p>
                  Know our journey in this industry and how we become one of the
                  most active service providers.
                </p>
                <span class="section-title-bg">Fun Facts</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6">
              <div class="count-box text-center">
                <div class="glyph">
                  <i class="fas fa-hand-holding-heart"></i>
                </div>
                <h2 class="counter">
                  <span></span>0
                </h2>
                <p>Clients Worked With</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
              <div class="count-box text-center">
                <div class="glyph">
                  <i class="far fa-handshake"></i>
                </div>
                <h2 class="counter">
                  <span></span>5
                </h2>
                <p>Completed Projects</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="count-box text-center">
                <div class="glyph">
                  <i class="fas fa-trophy"></i>
                </div>
                <h2 class="counter">
                  <span></span>0
                </h2>
                <p>Winning Awards</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Achievement;
