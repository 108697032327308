import React from "react";

function Services() {
  return (
    <div>
      <div class="mt-130">
        <section id="services" class="services ptb-100">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                  <h2>Services</h2>
                  <p>
                    Mindout infosystem is one of the leading software and
                    application solutions built with specialized teams that
                    offer a wide range of services Includes web applications,
                    mobile applications, web desiging, and DevOps solutions. Our
                    motive is to deliver the best and building long-term
                    relationships with our clientele through our cost-effective
                    and superior quality client services.
                  </p>
                  <span class="section-title-bg">Services</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                  <div class="glyph">
                    <i class="fas fa-user-friends"></i>
                  </div>
                  <h3>End to end support </h3>
                  <p>
                    We believe in building relationships, with 24x7 customer
                    support we are always open for our client assistance.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                  <div class="glyph">
                    <i class="fas fa-dice-d20"></i>
                  </div>
                  <h3>Seo friendly</h3>
                  <p>
                    We develop the site while keeping evolving Google algorithm
                    in mind so that client doesn’t need to bring chances again
                    and rank better on SERP.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                  <div class="glyph">
                    <i class="fas fa-headset"></i>
                  </div>
                  <h3>Pre/post consultation</h3>
                  <p>
                    We provide pre and post service and support consultation to
                    help clients make decisions according to their business
                    need.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                  <div class="glyph">
                    <i class="fas fa-chart-line"></i>
                  </div>
                  <h3>Technology upgrade</h3>
                  <p>
                    We use latest technology stack to build software and
                    application that makes sense and ready to go development.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                  <div class="glyph">
                    <i class="fab fa-hubspot"></i>
                  </div>
                  <h3>All-round solution</h3>
                  <p>
                    While being in the IT industry we best serve our client and
                    maintenance crystal clear transparency for business.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                  <div class="glyph">
                    <i class="fab fa-connectdevelop"></i>
                  </div>
                  <h3>Devops</h3>
                  <p>
                    We follow devops model to refine the development process and
                    structure with proper trial and testing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Services;
