import React from "react";

function TopContainer() {
  return (
    <div>
      <div class="hompage-slides-wrapper">
        <div class="owl-carousel owl-theme homepage-slides owl-loaded">
          <div class="owl-stage-outer">
            <div class="owl-stage">
              <div class="owl-item active">
                <div class="single-slider-item slide-bg-1">
                  <div class="diplay-table">
                    <div class="display-table-cell">
                      <div class="container">
                        <div class="row">
                          <div class="col-lg-7">
                            <span class="hero-text">Mindout infosystem</span>
                            <h1>You Imagine, We create</h1>
                            <p>We convert an idea into reality</p>
                            <div class="center-wrap">
                              <a class="btn-a" href="/#">
                                <div class="button">
                                  get started
                                  <i class="fa fa-long-arrow-right"></i>
                                  <div class="mask"></div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="owl-thumbs">
          <div class="owl-thumb-item">
            <div class="glyph">
              <i class="fas fa-laptop"></i>
            </div>
            <h3>Website Beautification</h3>
            <p>
              We follow updated technology to make the website speak naturally
              with a unique design that leads to higher session time and
              conversion rate.
            </p>
          </div>
          <div class="owl-thumb-item">
            <div class="glyph">
              <i class="fas fa-laptop-code"></i>
            </div>
            <h3>Application Development</h3>
            <p>
              We build fast and secure web and mobile applications for all sorts
              of business types that entertain both iOS and Android platform.
            </p>
          </div>
          <div class="owl-thumb-item">
            <div class="glyph">
              <i class="fas fa-box-open"></i>
            </div>
            <h3>UI/UX Interface</h3>
            <p>
              We design a website that feels good while showcasing all features
              and development with the latest technology to make it load faster.
            </p>
          </div>
          <div class="owl-thumb-item">
            <div class="glyph">
              <i class="fab fa-connectdevelop"></i>
            </div>
            <h3> DevOps Delivery</h3>
            <p>
              We follow the DevOps method to cater to our clients and to
              maximize their services that make the website holistically
              optimized.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopContainer;
