import React from "react";

function Contact() {
  return (
    <div>
      <section id="contact" class="contact-area ptb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
              <div class="section-title">
                <h2>Our Contact</h2>
                <p>
                  Connect with our dedicated developers team to analyse and
                  build up your business.
                </p>
                <span class="section-title-bg">Contact</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="address-area">
                <div class="addess">
                  <i class="fa fa-map-marker"></i>
                  <h4>Address</h4>
                  <p>2750 Quadra Street Victoria, Canada.</p>
                </div>
                <div class="email">
                  <i class="fa fa-envelope"></i>
                  <h4>Email</h4>
                  <p>
                    <a
                      href="/cdn-cgi/l/email-protection"
                      class="__cf_email__"
                      data-cfemail="28404d444447684f44475051064b4745"
                    >
                      [email&#160;protected]
                    </a>
                  </p>
                </div>
                <div class="phone">
                  <i class="fa fa-phone"></i>
                  <h4>Phone</h4>
                  <p>+1-325-555-0156</p>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-8">
              <div class="contact-form">
                <form novalidate="" id="contactForm" class="needs-validation">
                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <input
                          class="form-control"
                          name="name"
                          id="name"
                          required=""
                          placeholder="Name"
                          autoComplete="off"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          id="email"
                          required=""
                          placeholder="Email"
                          autoComplete="off"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="number"
                          id="number"
                          placeholder="Phone"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          name="message"
                          id="description"
                          placeholder="Your message"
                          required=""
                          rows="5"
                          autoComplete="off"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="center-wrap text-center">
                    <div class="button">
                      <button type="submit">
                        Submit <i class="fa fa-long-arrow-right"></i>{" "}
                      </button>
                      <div class="mask"></div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
