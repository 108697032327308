import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

function Footer() {
  return (
    <div>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <p class="copyright">© 2020 All Rights Reserved.</p>
            </div>
            <div class="col-md-3">
            <a href="/policy" class="footer-policy"
                  >
                  <p class="copyright">Privacy Policy</p>
                </a>
              
            </div>
            <div class="col-md-5">
              <div class="social-icons bottom">
                <ul class="list-inline">
                  <li>Follow Us On: </li>
                  <li>
                    <a href="https://instagram.com/mindout_infosystem?utm_medium=copy_link">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MindoutInfosys1?s=09">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="/#">
                      <i class="fa fa-google-plus"></i>
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.linkedin.com/in/mindout-infosystem-b980b2217">
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
