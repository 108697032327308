import React from "react";
import partner1 from "../assets/static/partner1.png";
import partner2 from "../assets/static/partner2.png";
import partner3 from "../assets/static/partner3.png";
import partner4 from "../assets/static/partner4.png";

function Partner() {
  return (
    <div>
      <section class="our-partners ptb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
              <div class="section-title">
                <h2>Our Partners</h2>
                <p>
                  Know our partner and our strong business relation With
                  industry experts that work with us.
                </p>
                <span class="section-title-bg">Partners</span>
              </div>
            </div>
          </div>
          <div class="owl-carousel owl-theme partners-slides owl-loaded owl-drag">
            <div class="owl-stage-outer">
              <div class="owl-stage">
                <div class="owl-item col-lg-3 col-sm-6">
                  <div class="single-partner-logo">
                    <a class="logo-preview" href="/#">
                      <img src={partner1} alt="partnerLogo" />
                    </a>
                  </div>
                </div>
                <div class="owl-item col-lg-3 col-sm-6">
                  <div class="single-partner-logo">
                    <a class="logo-preview" href="/#">
                      <img src={partner2} alt="partnerLogo" />
                    </a>
                  </div>
                </div>
                <div class="owl-item col-lg-3 col-sm-6" md="6">
                  <div class="single-partner-logo">
                    <a class="logo-preview" href="/#">
                      <img src={partner3} alt="partnerLogo" />
                    </a>
                  </div>
                </div>
                <div class="owl-item col-lg-3 col-sm-6 " md="6">
                  <div class="single-partner-logo">
                    <a class="logo-preview" href="/#">
                      <img src={partner4} alt="partnerLogo" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Partner;
