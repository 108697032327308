import React from "react";
import "../assets/css/index.css";
import Offer from "./Offer";
import Services from "./Services";
import Policy from "./Policy";
import TopContainer from "./TopContainer";
import Work from "./Work";
import About from "./About";
import Achievement from "./Achievement";
import Contact from "./Contact";
import Partner from "./Partner";

import Testinomal from "./Testinomal";

function Home() {
  return (
    <div>   
      <TopContainer />
      <Services />
      <Offer />
      <Work />
      <About />
      <Achievement />
      <Testinomal />
      <Partner />   
      <Contact />
     
    </div>
  );
}

export default Home;
