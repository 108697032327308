import React from "react";
import work1 from "../assets/static/work-1.jpg";
import work2 from "../assets/static/work-2.jpg";
import work3 from "../assets/static/work-3.jpg";
import work4 from "../assets/static/work-4.jpg";
import work5 from "../assets/static/work-5.jpg";
import work6 from "../assets/static/work-6.jpg";

function Work() {
  return (
    <div>
      <section id="works" class="our-works ptb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
              <div class="section-title">
                <h2>Our Works</h2>
                <p>
                  We take what the future promises and make it real for our
                  customers today. Through our expert team, we build best in
                  class software applications with a unique combination of
                  software technology and digital transformation
                </p>
                <span class="section-title-bg">Our Works</span>
              </div>
            </div>
          </div>
          <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4">
              <div class="work-details">
                <img src={work1} alt="sample" />
                <div class="box-content">
                  <h3 class="title">Work Details</h3>
                  <ul class="icon">
                    <li>
                      <a href="/#">
                        <i class="fa fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="work-details">
                <img src={work2} alt="sample" />
                <div class="box-content">
                  <h3 class="title">Work Details</h3>
                  <ul class="icon">
                    <li>
                      <a href="/#">
                        <i class="fa fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="work-details">
                <img src={work3} alt="sample" />
                <div class="box-content">
                  <h3 class="title">Work Details</h3>
                  <ul class="icon">
                    <li>
                      <a href="/#">
                        <i class="fa fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="work-details">
                <img src={work4} alt="sample" />
                <div class="box-content">
                  <h3 class="title">Work Details</h3>
                  <ul class="icon">
                    <li>
                      <a href="/#">
                        <i class="fa fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="work-details">
                <img src={work5} alt="sample" />
                <div class="box-content">
                  <h3 class="title">Work Details</h3>
                  <ul class="icon">
                    <li>
                      <a href="/#">
                        <i class="fa fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="work-details">
                <img src={work6} alt="sample" />
                <div class="box-content">
                  <h3 class="title">Work Details</h3>
                  <ul class="icon">
                    <li>
                      <a href="/#">
                        <i class="fa fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Work;
