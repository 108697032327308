import React from "react";
import laptop from "../assets/static/mac-pro.png";

function Offer() {
  return (
    <div>
      <section class="cta-area cta-bg-img">
        <div class="diplay-table">
          <div class="display-table-cell">
            <div class="container">
              <div class="row">
                <div class="col-md-7 col-lg-7">
                  <div class="cta-info">
                    <h3>
                      Reason why we are known for leading software development
                      company
                    </h3>
                    <p>
                      The perfect blend of mature processes, flexible delivery,
                      effective project management, latest technology support
                      makes the Mindout info system the best Software and
                      application development platform for all types of business
                      needs.
                    </p>
                  </div>
                </div>
                <div class="col-md-5 col-lg-5 cta-img">
                  <img class="img-fluid" src={laptop} alt="mac-pro" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Offer;
